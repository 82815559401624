<template>
  <div class=" mt-2">
    <b-breadcrumb class="breadcrumb-pipes">
      <b-breadcrumb-item href="/settings/admin-panel/">
        Admin Panel
      </b-breadcrumb-item>
      <b-breadcrumb-item href="/settings/user-role">
        Roles and Permissions
      </b-breadcrumb-item>
      <b-breadcrumb-item href="/settings/personal-settings">
        Personal Settings
      </b-breadcrumb-item>
    </b-breadcrumb>
    <b-card
      class="mb-0"
      :title="$t('settings.intermediary.titles.manage_intermediaries')"
    >
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row align-h="between">
            <b-col cols="12" md="3">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                cle
                :placeholder="$t('general_text.search_table')"
              />
            </b-col>
            <b-col v-if="$Can('add_intermediary')" cols="12" md="3">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="success" @click="createIntermediaryModal">
                  <span class="text-nowrap">
                    {{
                      $t(
                        "settings.intermediary.buttons.create_new_intermediary"
                      )
                    }}</span
                  >
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refIndermediaryTable"
          class="position-relative p-2"
          :items="allIntermediaries"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          :fixed="true"
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          :empty-text="$t('general_messages.no_matching_recors_found')"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template #cell(index)="data">
            <b
              ><span class="font-weight-bold">
                <strong class="text-primary">#{{ data.index + 1 }}</strong>
              </span></b
            >
          </template>
          <!-- Column: Intermediary -->
          <template #cell(Intermediary)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Intermediary ? data.item.Intermediary : "N/A"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Direction -->
          <template #cell(Direction)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Direction ? data.item.Direction : "N/A"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="deleteIntermediary(data.item)">
                <feather-icon icon="DeleteIcon" />
                <span class="align-middle ml-50">
                  {{
                    $t("settings.intermediary.buttons.delete_intermediary")
                  }}</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
      {{ /*intermediary create modal*/ }}
      <b-modal
        id="modal-prevent-closing"
        v-model="intermediaryCreateModal"
        :title="$t('settings.intermediary.titles.create_new_intermediary')"
        @hidden="resetIntermediaryCreateModal"
        size="md"
        :ok-title="$t('settings.intermediary.buttons.create')"
        :cancel-title="$t('settings.intermediary.buttons.cancel')"
        @ok="createIntermediary"
      >
        <b-overlay :show="createIntermediaryloading" rounded="sm" no-fade>
          <b-container>
            <validation-observer ref="createValidation">
              <b-form>
                <b-row class="mt-2 mb-3">
                  <b-col cols="12" xl="12" md="12">
                    <!-- intermediary-->
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.intermediary.form.intermediary')"
                        rules="required"
                      >
                        <b-form-input
                          id="intermediary"
                          v-model="newIntermediary.title"
                          name="intermediary"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="
                            $t('settings.intermediary.form.intermediary')
                          "
                          autofocus
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" xl="12" md="12">
                    <!-- direction -->
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.intermediary.form.direction')"
                        rules="required"
                      >
                        <v-select
                          v-model="newIntermediary.direction"
                          :options="optIntermediries"
                          :clearable="false"
                          :placeholder="
                            $t('settings.intermediary.form.select_direction')
                          "
                          :reduce="(val) => val"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-container>
          <template #overlay v-if="createIntermediaryloading">
            <div
              class="text-center d-flex justify-content-center align-items-start"
            >
              <b-icon icon="stopwatch" font-scale="3" animation="cylon">
              </b-icon>
              <p id="cancel-label">
                <feather-icon icon="DownloadIcon" />
                {{
                  $t(
                    "settings.intermediary.messages.intermediary_create_message"
                  )
                }}
              </p>
            </div>
          </template>
        </b-overlay>
        {{/****END*** intermediary create modal*/}}
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../../settingsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import { BBreadcrumb, BBreadcrumbItem } from "bootstrap-vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BBreadcrumb,
    BBreadcrumbItem,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      allIntermediaries: [],
      intermediaryCreateModal: false,
      allCreatedIntermediaries: [],
      accessLevel: null,
      filterModal: null,
      updatePermloading: false,
      createIntermediaryloading: false,
      newIntermediary: {
        title: null,
        direction: null,
      },
      optIntermediries: ["GIVEN", "TAKEN"],
      filterOn: ["Intermediary", "Direction"],
      tableColumns: [
        {
          key: "index",
          label: "",
        },
        {
          key: "Intermediary",
          label: this.$t("settings.intermediary.table.intermediary"),
          sortable: true,
        },
        {
          key: "Direction",
          label: this.$t("settings.intermediary.table.direction"),
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("settings.intermediary.table.actions"),
          sortable: false,
        },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      filter: null,

      loading: false,
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["authUser"]),
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  mounted() {
    this.getIntermediaries();
  },
  methods: {
    getIntermediaries() {
      this.loading = true;
      store
        .dispatch("settings/fetchIntermediaries")
        .then((res) => {
          this.allIntermediaries = res.data;
          this.totalRows = res.data.length;
          this.loading = false;
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
        });
    },
    createIntermediaryModal() {
      this.intermediaryCreateModal = true;
    },
    createIntermediary(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.createValidation.validate().then((success) => {
        if (success) {
          this.createIntermediaryloading = true;
          store
            .dispatch("settings/checkExistingIntermediary", {
              intermediary: this.newIntermediary,
            })
            .then((res) => {
              if (res.data.existing == 1) {
                this.existingIntermediaryMessage();
                this.createIntermediaryloading = false;
              } else {
                store
                  .dispatch("settings/createNewIntermediary", {
                    intermediary: this.newIntermediary,
                  })
                  .then((res) => {
                    if (res.data.info == true) {
                      this.createIntermediaryloading = false;
                      this.resetIntermediaryCreateModal();
                      this.getIntermediaries();
                      this.createIntermediaryToastMessage();
                    } else {
                      this.createIntermediaryloading = false;
                      this.wrongToastMessage(res.data.message);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    resetIntermediaryCreateModal() {
      this.intermediaryCreateModal = false;
      this.newIntermediary = {
        title: null,
        direction: null,
      };
    },
    deleteIntermediary(intermediary) {
      const self = this;
      this.$swal({
        title:
          this.$t("settings.intermediary.messages.sure_delete_intermediary") +
          ` ${intermediary.Intermediary}`,
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t(
          "settings.intermediary.buttons.yes_delete_it"
        ),
        cancelButtonText: this.$t("settings.intermediary.buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-success mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;
          store
            .dispatch("settings/deleteIntermediary", {
              intermediary: intermediary,
            })
            .then((res) => {
              if (res.data.info == true) {
                self.getIntermediaries();
                self.loading = false;
                self.$swal.fire({
                  icon: "success",
                  title: "Deleted!",
                  text: this.$t(
                    "settings.intermediary.messages.intermediary_deleted"
                  ),
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                });
              } else {
                this.intermediaryNotFoundMessage();
                self.loading = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t(
            "settings.intermediary.messages.database_connection_error"
          ),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    existingIntermediaryMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("settings.intermediary.messages.exist_intermediary"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    intermediaryNotFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t(
            "settings.intermediary.messages.intermediary_not_found"
          ),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    createIntermediaryToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "settings.intermediary.messages.intermediary_create_success"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
